//General Configuration
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "prod";
const API_URL = process.env.REACT_APP_API_URL || "";
const UI_REDIRECTION_ORIGIN = process.env.REACT_APP_UI_REDIRECTION_ORIGIN || "";

//Authentication Configuration
const GRAPH_API_BASE_URL = process.env.REACT_APP_GRAPH_API_BASE_URL || "";
const GRAPH_API_VERSION = process.env.REACT_APP_GRAPH_API_VERSION || "";
const APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "";

//Security Group Configuration
const SG_JOIN_BASE_URL = process.env.REACT_APP_SG_JOIN_BASE_URL || "";
const SG_LEAVE_BASE_URL = process.env.REACT_APP_SG_LEAVE_BASE_URL || "";

//Telemetry Configuration
const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME || "";
const INSTRUMENTATION_KEY = process.env.REACT_APP_INSTRUMENTATION_KEY || "";
const SERVICE_OFFERING = process.env.REACT_APP_SERVICE_OFFERING || "";
const COMPONENT_NAME = process.env.REACT_APP_COMPONENT_NAME || "";
const COMPONENT_ID = process.env.REACT_APP_COMPONENT_ID || "";
const SERVICE_LINE = process.env.REACT_APP_SERVICE_LINE || "";
const SERVICE = process.env.REACT_APP_SERVICE || "";
const ENABLE_BENCHMARKING = JSON.parse(process.env.REACT_APP_ENABLE_BENCHMARKING ? process.env.REACT_APP_ENABLE_BENCHMARKING : "false");
const TRACK_USER_NAME = JSON.parse(process.env.REACT_APP_TRACK_USER_NAME ? process.env.REACT_APP_TRACK_USER_NAME : "false");
const XCV = process.env.REACT_APP_XCV || "";

//Onboard User API
const ONBOARD_API_URL = process.env.REACT_APP_ONBOARD_API_URL || "";

export {
  //General Configuration
  ENVIRONMENT,
  API_URL,
  UI_REDIRECTION_ORIGIN,

  //Authentication Configuration
  GRAPH_API_BASE_URL,
  GRAPH_API_VERSION,
  APP_CLIENT_ID,

  //Onboard Configuration
  ONBOARD_API_URL,

  //Security Group Configuration
  SG_JOIN_BASE_URL,
  SG_LEAVE_BASE_URL,

  //Telemetry Configuration
  ENVIRONMENT_NAME,
  INSTRUMENTATION_KEY,
  SERVICE_OFFERING,
  COMPONENT_NAME,
  COMPONENT_ID,
  SERVICE_LINE,
  SERVICE,
  ENABLE_BENCHMARKING,
  TRACK_USER_NAME,
  XCV,
}
