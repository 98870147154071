import "./web.config";
import "@progress/kendo-theme-default/dist/all.css";

import * as EnvConfig from "./env-config";

import { ApolloClient } from "apollo-boost";
import { AppPlatform } from "./app-platform";
import { Configuration } from "@azure/msal-browser";
import { InMemoryCache } from "apollo-cache-inmemory";
import React from "react";
import ReactDOM from "react-dom";
import { createHttpLink } from "apollo-link-http";

const cache = new InMemoryCache({
  // https://github.com/apollographql/apollo-client/issues/1913
  addTypename: false
});

const msalConfig: Configuration = {
  auth: {
    clientId: `api://${EnvConfig.APP_CLIENT_ID}`,
    authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.origin.toString()
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

const client = new ApolloClient({
  cache,
  link: createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}`
  })
});

ReactDOM.render(<AppPlatform client={client} msalConfig={msalConfig} />, document.getElementById("root"));
