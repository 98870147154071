import * as React from "react";

import { Configuration, PublicClientApplication } from "@azure/msal-browser";

import ApolloClient from "apollo-client";
import { AppPlatformAuth } from "./app-platform-auth";
import { MsalProvider } from "@azure/msal-react";

interface AppPlatformProps {
  msalConfig: Configuration;
  client: ApolloClient<object>;
}

/** The app platform */
export const AppPlatform: React.FunctionComponent<AppPlatformProps> = (props: AppPlatformProps): JSX.Element => {
  const msalInstance = new PublicClientApplication(props.msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <AppPlatformAuth client={props.client} />
    </MsalProvider>
  );
};
