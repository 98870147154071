import * as React from "react";
import * as Styles from "./digital-ops-home.styles";

import { ButtonBar, COLORS, DEPTH, PageWrapper } from "@epic/doc-platform-components";
import { ICommandBarItemProps, KeyCodes, PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";

import { Context } from "@epic/doc-platform-context";
import { parse } from "qs";
import { style } from "typestyle";

/**
 * The default page for the Digital Operations Center which is rendered when no app is specified in the route.
 * Handles looking up the user's last used app for redirection.
 */
export const DigitalOpsHome: React.FunctionComponent = (): JSX.Element => {
  const contextHook = useContext(Context);

  const [switching, setSwitching] = useState(false);
  const [cacheChecked, setCacheChecked] = useState(false);

  const colors = new COLORS();
  const depth = new DEPTH();

  const docAppSelectionCacheKey = "DOC_APP_SELECTION";

  useEffect(() => {
    if (!contextHook.context.profilesLoading) {
      const shouldCheckCache = parse(location.search, { ignoreQueryPrefix: true }).cache;

      if (shouldCheckCache !== "no") {
        const cachedAppSelection = localStorage.getItem(docAppSelectionCacheKey);

        if (cachedAppSelection) {
          const cachedApp = contextHook.context.applicationProfileSets.find(
            (set) => set.application === cachedAppSelection
          );

          if (cachedApp) {
            localStorage.setItem(docAppSelectionCacheKey, cachedApp.application);

            if (cachedApp.url.includes("azurewebsites.net")) {
              window.open(`${cachedApp.url}#/${cachedApp.application}`, "_self");
            } else {
              window.open(`${cachedApp.url}`, "_self");
            }
          }
        }

        setTimeout(() => {
          setCacheChecked(true);
        }, 500);
      } else {
        setCacheChecked(true);
      }
    }
  }, [contextHook.context.profilesLoading]);

  const element = (
    <Stack
      className={style({ height: "100vh" })}
      verticalAlign="center"
      horizontalAlign="center"
      tokens={{ childrenGap: 40 }}
    >
      {contextHook.context.profilesLoading || switching || !cacheChecked ? (
        <Stack.Item>
          <Spinner size={SpinnerSize.large} />
        </Stack.Item>
      ) : (
        <PageWrapper
          isLoading={contextHook.context.profilesLoading}
          pageConfig={{ pageTitle: "Digital Operations Center", codename: "", urlPartial: "", hasLeftNav: false }}
        >
          <Stack tokens={{ childrenGap: 30 }} className={Styles.rootStack}>
            <Stack.Item>
              <Stack horizontal horizontalAlign="space-between">
                <Stack.Item>
                  <h2>Apps</h2>
                </Stack.Item>
                <Stack.Item>
                  <PrimaryButton
                    onClick={(): void => {
                      console.log("Get more apps");
                    }}
                  >
                    Get more Apps
                  </PrimaryButton>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
                {contextHook.context.applicationProfileSets
                  .filter((profileSet) => profileSet.onboarded && profileSet.userProfiles.length > 0)
                  .map((set) => {
                    return (
                      <Stack.Item key={set.application}>
                        <div
                          role="link"
                          tabIndex={0}
                          className={Styles.appTile(colors, depth)}
                          onKeyDown={(evt): void => {
                            if (evt.keyCode === KeyCodes.enter) {
                              localStorage.setItem(docAppSelectionCacheKey, set.application);

                              if (set.userProfiles[0].applicationUrl.includes("azurewebsites.net")) {
                                window.open(
                                  `${set.userProfiles[0].applicationUrl}#/${set.userProfiles[0].application}`,
                                  "_self"
                                );
                              } else {
                                window.open(`${set.userProfiles[0].applicationUrl}`, "_self");
                              }
                            }
                          }}
                          onClick={(): void => {
                            localStorage.setItem(docAppSelectionCacheKey, set.application);

                            if (set.userProfiles[0].applicationUrl.includes("azurewebsites.net")) {
                              window.open(
                                `${set.userProfiles[0].applicationUrl}#/${set.userProfiles[0].application}`,
                                "_self"
                              );
                            } else {
                              window.open(`${set.userProfiles[0].applicationUrl}`, "_self");
                            }
                          }}
                        >
                          <Stack horizontalAlign="start" className={Styles.appTileStack}>
                            <Stack.Item>
                              <Stack className={Styles.buttonBarStack} horizontalAlign="end">
                                <ButtonBar
                                  isLoading={false}
                                  overflowItems={[
                                    ...set.userProfiles.map((profile) => {
                                      const item: ICommandBarItemProps = {
                                        key: profile.name,
                                        text: profile.displayName,
                                        onClick: (): void => {
                                          setSwitching(true);
                                          localStorage.setItem(docAppSelectionCacheKey, set.application);
                                          contextHook.contextMutations.switchApplicationProfile(profile);
                                        }
                                      };

                                      return item;
                                    })
                                  ]}
                                />
                              </Stack>
                            </Stack.Item>
                            <Stack.Item className={Styles.contentStackItem}>
                              <Stack className={Styles.contentStackInternal} verticalAlign={"center"}>
                                <h3>{set.displayName}</h3>
                                <h6 className={Styles.description}>{set.description}</h6>
                              </Stack>
                            </Stack.Item>
                          </Stack>
                        </div>
                      </Stack.Item>
                    );
                  })}
              </Stack>
            </Stack.Item>
          </Stack>
        </PageWrapper>
      )}
    </Stack>
  );

  return element;
};
