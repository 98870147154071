import * as React from "react";
import * as Styles from "./app-platform.styles";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";

import ApolloClient from "apollo-client";
import { AppPlatformProviders } from "./app-platform-providers";
import { Fragment } from "react";
import { InteractionType } from "@azure/msal-browser";
import { Stack } from "@fluentui/react";

interface AppPlatformAuthProps {
  client: ApolloClient<object>;
}

/** Authentication wrapper for MSAL. */
export const AppPlatformAuth: React.FunctionComponent<AppPlatformAuthProps> = (
  props: AppPlatformAuthProps
): JSX.Element => {
  useMsalAuthentication(InteractionType.Redirect);

  return (
    <Fragment>
      <AuthenticatedTemplate>
        <AppPlatformProviders client={props.client} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Stack horizontalAlign="center" verticalAlign="center" className={Styles.height}>
          <Stack.Item>
            <p>Signing in...</p>
          </Stack.Item>
        </Stack>
      </UnauthenticatedTemplate>
    </Fragment>
  );
};
