import * as EnvConfig from "./env-config";
import * as React from "react";

import { ContextProvider, Environments } from "@epic/doc-platform-context";
import {
  TelemetryEnvironmentProps,
  TelemetryProvider,
  getAppInsights,
  trackException
} from "@epic/doc-platform-telemetry";

import { ApolloClient } from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import { AppPlatformContent } from "./app-platform-content";
import { COLORS } from "@epic/doc-platform-components";
import { CoherenceCustomizations } from "@coherence-design-system/styles";
import { HashRouter } from "react-router-dom";
import { PropsWithChildren } from "react";
import { useMsal } from "@azure/msal-react";

interface AppPlatformProvidersProps {
  client: ApolloClient<object>;
}

/** Providers. */
export const AppPlatformProviders: React.FunctionComponent<AppPlatformProvidersProps> = (
  props: PropsWithChildren<AppPlatformProvidersProps>
): JSX.Element => {
  const msalContext = useMsal();

  const modifiedCustomizations = CoherenceCustomizations;
  modifiedCustomizations.settings.theme.palette.themePrimary = new COLORS().docThemePrimary;

  const envProps: TelemetryEnvironmentProps = {
    serviceOffering: EnvConfig.SERVICE_OFFERING,
    componentName: EnvConfig.COMPONENT_NAME,
    componentId: EnvConfig.COMPONENT_ID,
    environmentName: EnvConfig.ENVIRONMENT_NAME,
    serviceLine: EnvConfig.SERVICE_LINE,
    service: EnvConfig.SERVICE,
    trackUserName: EnvConfig.TRACK_USER_NAME,
    enableBenchmarking: EnvConfig.ENABLE_BENCHMARKING
  };

  const element = (
    <ApolloProvider client={props.client}>
      <HashRouter>
        <TelemetryProvider
          instrumentationKey={EnvConfig.INSTRUMENTATION_KEY}
          environmentProps={envProps}
          userName={msalContext.instance.getAllAccounts()[0].username ?? ""}
        >
          <ContextProvider
            client={props.client}
            msalContext={msalContext}
            environment={EnvConfig.ENVIRONMENT as keyof typeof Environments}
            onGetAppInsights={getAppInsights}
            onTrackException={trackException}
            clientId={EnvConfig.APP_CLIENT_ID}
            tenantId={"72f988bf-86f1-41af-91ab-2d7cd011db47"}
          >
            <AppPlatformContent />
          </ContextProvider>
        </TelemetryProvider>
      </HashRouter>
    </ApolloProvider>
  );

  return element;
};
