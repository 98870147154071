import { COLORS, DEPTH } from "@epic/doc-platform-components";

import { style } from "typestyle";

/** styles */
export const appTile = (colors: COLORS, depth: DEPTH): string =>
  style({
    backgroundColor: colors.docCardBackground,
    boxShadow: depth.depth4,
    height: 100,
    width: 200,
    border: "none",
    borderRadius: 8,
    textDecoration: "none !important",
    color: "unset",
    $nest: {
      "&:hover": {
        textDecoration: "none",
        color: "unset",
        backgroundColor: colors.docNeutralLighter,
        boxShadow: depth.depth16,
        cursor: "pointer"
      },
      "&:active": {
        textDecoration: "none",
        color: "unset",
        backgroundColor: colors.docNeutralLight
      }
    }
  });

export const rootStack = style({ paddingTop: 60 });

export const appTileStack = style({ height: 100, width: 200 });

export const buttonBarStack = style({
  height: 20,
  width: 200,
  $nest: {
    "& > div": {
      padding: 0,
      $nest: {
        "* > div": {
          padding: 0,
          borderTopRightRadius: 8,
          height: 30
        },
        ":hover": {
          color: "orange"
        }
      }
    }
  }
});

export const contentStackItem = style({
  height: "60px",
  padding: "0px 10px"
});

export const contentStackInternal = style({
  height: "100%"
});

export const description = style({
  lineHeight: "normal",
  height: "12.8px",
  overflow: "visible",
});
